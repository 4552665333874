import { useStaticQuery, graphql } from "gatsby";

const mergePostsAndSortByDate = (...args) => {
  const masterArray = [].concat(...args).sort((a, b) => {
    var dateA = a.post.dateGmt.toUpperCase();
    var dateB = b.post.dateGmt.toUpperCase();
    return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
  });
  return masterArray;
};

const query = graphql`
  {
    posts: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
      limit: 10
    ) {
      edges {
        post: node {
          id
          dateGmt
          title
          slug
          postType: nodeType
          featuredImage {
            node {
              srcSet
              mediaItemUrl
            }
          }
          additionalInfo {
            standfirst
            author
          }
          themeCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    dataStories: allWpDataStory(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
      limit: 10
    ) {
      edges {
        post: node {
          id
          postType: nodeType
          dateGmt
          title
          slug
          additionalInfo {
            standfirst
            author
          }
          dataCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    briefings: allWpBriefing(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
      limit: 10
    ) {
      edges {
        post: node {
          id
          postType: nodeType
          dateGmt
          title
          slug
          featuredImage {
            node {
              srcSet
              mediaItemUrl
              id
            }
          }
          additionalInfo {
            standfirst
            author
          }
          themeCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    publications: allWpPublication(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
      limit: 10
    ) {
      edges {
        post: node {
          id
          postType: nodeType
          dateGmt
          title
          slug
          featuredImage {
            node {
              srcSet
              mediaItemUrl
              id
            }
          }
          additionalInfo {
            standfirst
            author
          }
          themeCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    timelines: allWpTimeline(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
      limit: 10
    ) {
      edges {
        post: node {
          id
          postType: nodeType
          dateGmt
          title
          slug
          fields: timelineCustomFields {
            introduction
          }
          additionalInfo {
            standfirst
            author
          }
          featuredImage {
            node {
              srcSet
              mediaItemUrl
              id
            }
          }
        }
      }
    }
  }
`;

export const useAllPosts = ({ limit } = { limit: 10 }) => {
  const { posts, timelines, publications, briefings, dataStories } =
    useStaticQuery(query);
  return mergePostsAndSortByDate(
    posts.edges,
    timelines.edges,
    publications.edges,
    briefings.edges,
    dataStories.edges
  ).slice(0, limit);
};
