import React from "react";
import { Box, Grid, Text } from "theme-ui";
import { getFlatFields } from "../../lib/text-helpers";
import Card from "../card";
import ColumnContainer from "./column-container";
import Scroller from "./scroller";

const ScrollerIntro = ({ name, description }) => {
  return (
    <Box sx={{ pt: [3, 4, 5], mb: [3, 4, 5] }}>
      <ColumnContainer>
        <Grid columns={[1, 2]}>
          <Text sx={{ fontSize: [5, 6] }} as="h2" variant="title">
            {name}
          </Text>
          <Text sx={{ maxWidth: "600px" }} as="h3" variant="subtitle">
            {description}
          </Text>
        </Grid>
      </ColumnContainer>
    </Box>
  );
};

const ClosedScroller = ({ title, description, posts, to = "/test" }) => {
  return (
    <ColumnContainer>
      <Box
        sx={{
          bg: "offWhite",
          overflow: "hidden",
          borderRadius: 30,
          zIndex: 0,
        }}
      >
        <ScrollerIntro name={title} description={description} />
        <Scroller backgroundColorValues="244,244,244">
          {posts &&
            posts.map((post) => {
              const fields = getFlatFields(post);
              return (
                <Card
                  background="white"
                  key={post.slug}
                  title={post.title}
                  {...fields}
                  srcSet={post?.featuredImage?.node?.srcSet}
                  src={post?.featuredImage?.node?.mediaItemUrl}
                />
              );
            })}
        </Scroller>
        {/* <Flex
          sx={{
            pb: [3, 4],
            px: [3, 4],
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button as="a" href={to} sx={{ bg: "purple", color: "white" }}>
            See all articles
          </Button>
        </Flex> */}
      </Box>
    </ColumnContainer>
  );
};

export default ClosedScroller;
