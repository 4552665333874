import React from "react";
import { Box, Grid, Text } from "@theme-ui/components";
import AppButton from "../layout/app-button";
import ColumnContainer from "../layout/column-container";
import { useStaticQuery, graphql } from "gatsby";
import eventTrack from "../../lib/event-track";

const TwitterCallToAction = () => {
  const data = useStaticQuery(graphql`
    query TwitterBgQuery {
      image: file(relativePath: { eq: "twitter-background.jpg" }) {
        c: childImageSharp {
          f: fluid(maxWidth: 600, quality: 60) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <ColumnContainer>
      <Grid id="newsletter" columns={[1, 1, 3, 2]} gap={0}>
        <Box
          sx={{
            backgroundImage: `url(${data.image.c.f.src})`,
            backgroundColor: "#1DA1F2",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            borderRadius: ["30px 30px 0 0", null, "30px 0 0 30px"],
            height: "100%",
            minHeight: [300, 450, "initial"],
          }}
          src={data.image.c.f.src}
        />
        <Box
          sx={{
            bg: "highlight",
            borderRadius: ["0 0px 30px 30px", null, "0 30px 30px 0"],
            gridColumn: ["span 1", "span 2", null, "span 1"],
          }}
        >
          <Grid sx={{ p: [4, 5] }} columns={1} gap={[4]}>
            <Text
              sx={{
                color: "white",
                fontSize: [4, 5, 6],
                lineHeight: 1.4,
              }}
              as="h2"
            >
              Join the conversation on Twitter
            </Text>
            <Text as="p" sx={{ color: "white", lineHeight: 1.7 }}>
              Follow us for discussion and new post alerts
            </Text>
            <AppButton
              onClick={() => {
                eventTrack("Twitter Follow Clicked", {
                  props: {
                    url: window.location.pathname,
                  },
                  callback: () => {
                    window.location.href =
                      "https://twitter.com/intent/user?screen_name=AQ_insight";
                  },
                });
              }}
              colors={{
                default: {
                  color: "text",
                  background: "#1DA1F2",
                  iconColor: "text",
                },
              }}
              icon="arrowRight"
              type="submit"
              sx={{
                mt: [4],
                borderRadius: 20,
              }}
            >
              Follow Now
            </AppButton>
          </Grid>
        </Box>
      </Grid>
    </ColumnContainer>
  );
};

export default TwitterCallToAction;
