import { Box, Flex, Image, Text } from "@theme-ui/components";
import React from "react";
import AppButton from "../components/layout/app-button";
import buildImageUrl from "../lib/image-boss-helper";

const Card = ({
  background = "offWhite",
  label = "Data",
  title = "Example title",
  subtitle = "The latest 2021 figures. Lorem ipsum dolor sit amet, consectetur adispiscing.",
  color = "purple",
  buttonColors,
  to = "/",
  icon = false,
  srcSet,
  src,
  cta = "View more",
}) => (
  <Flex
    sx={{
      float: "right",
      flexDirection: "column",
      flexShrink: 0,
      bg: background,
      borderRadius: 20,
      width: [300, 340],
      overflow: "hidden",
      position: "relative",
      mr: [3, 4],
      scrollSnapAlign: "center",
      "&:first-of-type": {
        ml: [3, 4],
      },
      "&:last-of-type": {
        mr: [3, 4],
      },
    }}
  >
    {srcSet && (
      <Image
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: 200,
          display: "block",
          aspectRatio: "4 / 2.5",
          objectFit: "cover",
        }}
        src={buildImageUrl(src, 600)}
      />
    )}
    <Flex
      columns={1}
      gap={[2, 2, 3]}
      sx={{
        flex: 1,
        p: [3, 4],
        minHeight: 300,
        flexDirection: "column",
        "& > *": {
          mb: [3, 4],
        },
      }}
    >
      <Text variant="label" sx={{ color, my: [null, null, null, null] }}>
        {label}
      </Text>
      <Text
        as="h3"
        sx={{
          fontSize: [4, null, null, 5],
        }}
        variant="title"
      >
        {title}
      </Text>
      <Text as="p" sx={{ pb: srcSet ? [3, 4] : [4, 5, 6] }} variant="paragraph">
        {subtitle}
      </Text>
      <Box sx={{ marginTop: "auto", position: "relative" }}>
        <AppButton colors={{ ...buttonColors }} icon={icon} to={to}>
          {cta}
        </AppButton>
      </Box>
    </Flex>
  </Flex>
);

export default Card;
