export const getLink = ({ postType, slug, dataCategories }) => {
  switch (postType) {
    case "DataStory":
      return `/data-stories/${dataCategories?.nodes[0]?.slug}#${slug}`;
    case "Post":
      return `/blogs/${slug}`;
    case "Timeline":
      return `/timelines/${slug}`;
    case "Briefing":
      return `/briefings/${slug}`;
    case "Publication":
      return `/publications/${slug}`;
    default:
      return `/`;
  }
};

export const getIcon = ({ postType }) => {
  switch (postType) {
    case "DataStory":
      return `chart`;
    case "Post":
      return `report`;
    case "Timeline":
      return `mapMarker`;
    case "Briefing":
      return `chart`;
    case "Publication":
      return `report`;
    default:
      return `/`;
  }
};

export const getCta = (post) => {
  const types = {
    DataStory: "Explore the Data",
    Timeline: "Scroll the Timeline",
    Post: "Read More",
    Publication: "Read now",
    Briefing: "Read More",
  };
  return types[post.postType] ?? " ";
};

export const getColor = (post) => {
  const types = {
    DataStory: "darkGreen",
    Timeline: "blue",
    Post: "purple",
    Briefing: "coral",
    Publication: "coral",
  };
  return types[post.postType] ?? "";
};

export const getButtonColors = (post) => {
  const types = {
    DataStory: {
      default: {
        background: "darkGreen",
        text: "white",
      },
      hover: {
        background: "coral",
        text: "white",
      },
    },
    Timeline: {
      default: {
        background: "blue",
        text: "white",
      },
      hover: {
        background: "coral",
        text: "white",
      },
    },
    Post: {
      default: {
        background: "purple",
        iconColor: "purple",
        text: "white",
      },
      hover: {
        background: "darkRed",
        iconColor: "darkRed",
        text: "white",
      },
    },
    Briefing: {
      default: {
        background: "coral",
        text: "white",
      },
      hover: {
        background: "darkRed",
        text: "white",
      },
    },
    Publication: {
      default: {
        background: "coral",
        text: "white",
      },
      hover: {
        background: "darkRed",
        text: "white",
      },
    },
  };
  return types[post.postType] ?? {};
};

export const getLabel = (post) => {
  const types = {
    DataStory: "Data Story",
    Timeline: "Timeline",
    Post: post?.themeCategories?.nodes[0]?.name,
    Briefing: "Briefing",
    Publication: "Report",
  };
  return types[post.postType] ?? " ";
};

export const getSubtitle = (post) => {
  const types = {
    DataStory: post?.additionalInfo?.standfirst,
    Post: post?.additionalInfo?.standfirst,
    Timeline: post?.additionalInfo?.standfirst,
    Briefing: post?.additionalInfo?.standfirst,
    Publication: post?.additionalInfo?.standfirst,
  };
  return types[post.postType] ?? "Read More";
};

export const getFlatFields = (post) => {
  return {
    subtitle: getSubtitle(post),
    label: getLabel(post),
    cta: getCta(post),
    icon: getIcon(post),
    to: getLink(post),
    title: post.title,
    color: getColor(post),
    buttonColors: getButtonColors(post),
  };
};
