import React from "react";
import Scroller from "../layout/scroller";
import Card from "../card";
import { getFlatFields } from "../../lib/text-helpers";
import { useAllPosts } from "../../data-hooks/all-posts";

const ReusableLatestContent = ({ limit = 10, title = false }) => {
  const posts = useAllPosts({ limit });
  return (
    <Scroller title={title ? title : "latest"}>
      {posts &&
        posts.map(({ post }) => {
          const fields = getFlatFields(post);
          return (
            <Card
              key={post.slug}
              title={post.title}
              {...fields}
              srcSet={post?.featuredImage?.node?.srcSet}
              src={post?.featuredImage?.node?.mediaItemUrl}
            />
          );
        })}
    </Scroller>
  );
};

export default ReusableLatestContent;
