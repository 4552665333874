import React from "react";
import { Box, Flex, Grid, Image, Text } from "theme-ui";
import buildImageUrl from "../../lib/image-boss-helper";
import AppButton from "../layout/app-button";
import ColumnContainer from "../layout/column-container";

const ClosedTeaser = ({
  color = "purple",
  title = "Learning from other nations",
  subtitle,
  label,
  icon = "rightArrow",
  cta = "Read more",
  to = "/",
  src,
  externalImage = false,
}) => {
  return (
    <ColumnContainer>
      <Grid
        columns={[1, 1, 3]}
        gap={[2, 0]}
        sx={{
          bg: "offWhite",
          borderRadius: 30,
          overflow: "hidden",
        }}
      >
        <Flex
          sx={{
            order: [1, null, 0],
            flexDirection: "column",
            alignItems: "flex-start",
            p: [3, 4],
          }}
        >
          <Text
            variant="label"
            sx={{
              color: color,
            }}
          >
            {label}
          </Text>
          <Text as="h2" variant="title">
            {title}
          </Text>
          <Text
            variant="subtitle"
            sx={{
              width: "80%",
              mb: [4],
            }}
          >
            {subtitle}
          </Text>
          <AppButton
            colors={{
              default: { background: "blue", text: "white" },
              hover: { background: "darkRed" },
            }}
            sx={{ mt: "auto" }}
            to={to}
            color={color}
            icon={icon}
          >
            {cta}
          </AppButton>
        </Flex>
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center center",
            gridColumn: "span 2",
          }}
        >
          <Image
            sx={{
              aspectRatio: "3/2",
              objectFit: "cover",
              display: "block",
              minWidth: "100%",
            }}
            src={externalImage ? src : buildImageUrl(src, 800)}
          />
        </Box>
      </Grid>
    </ColumnContainer>
  );
};

export default ClosedTeaser;
