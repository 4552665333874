import { Box, Flex, Text } from "@theme-ui/components";
import React, { useEffect, useRef, useState } from "react";

const DynamicPadding = ({ targetRef }) => {
  const [width, setWidth] = useState(0);
  const [resized, setResized] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      setResized((val) => val + 1);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [resized]);

  useEffect(() => {
    const styles = window.getComputedStyle(targetRef.current);
    setWidth(styles.marginLeft);
  }, [targetRef, resized]);
  return <Box style={{ minWidth: width, height: "100%" }} />;
};

const Scroller = ({
  title = "Latest",
  children,
  backgroundColorValues = "255,255,255",
}) => {
  const containerRef = useRef(null);
  return (
    <Box as="section" sx={{ position: "relative" }}>
      <Box
        ref={containerRef}
        as="nav"
        sx={{
          maxWidth: "column",
          px: [3, 4, 5],
          mx: "auto",
        }}
      >
        <Flex
          sx={{
            justifyContent: "space-between",
            borderTop: "1px solid",
            pt: [2, 3],
          }}
          as="section"
        >
          <Text sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {title}
          </Text>
          <Box
            sx={{
              "& button": {
                fontWeight: "bold",
                border: "none",
                py: 0,
                px: 2,
              },
            }}
          >
            {/* <Button>&larr;</Button>
            <Button>&rarr;</Button> */}
          </Box>
        </Flex>
      </Box>
      <Flex
        sx={{
          scrollSnapType: "x mandatory",
          overflowX: "scroll",
          scrollBehaviour: "smooth",
          mt: [3, 4],
          px: [3, 4],
          // ml: [3, 4],
          pb: [4],
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: [30, 50],
            position: "absolute",
            left: 0,
            bottom: 0,
            // backgroundImage: `linear-gradient(90deg, rgba(255,255,255, 0.8) -100%, rgba(0,0,0,0) 100%)`,
            backgroundImage: `-webkit-linear-gradient(360deg, rgb(${backgroundColorValues}) -20%, rgba(${backgroundColorValues}, 0.001))`,
          }}
        />
        <DynamicPadding targetRef={containerRef} />
        {children}
        <Box
          sx={{
            height: "100%",
            width: [30, 50],
            position: "absolute",
            right: 0,
            bottom: 0,
            backgroundImage: `-webkit-linear-gradient(360deg, rgba(${backgroundColorValues},0.001), rgb(${backgroundColorValues}) 120%)`,
          }}
        />
        {/* <Box
          sx={{
            backgroundImage:
              "linear-gradient(90deg,rgba(255,255,255, 0), offWhite 90%)",
            top: 0,
            height: ["calc(100% - 112px)"],
            width: [80],
            position: "absolute",
            right: 0,
            mt: [80],
          }}
        ></Box> */}
      </Flex>
    </Box>
  );
};

export default Scroller;
