import { graphql } from "gatsby";
import React from "react";
import { Grid } from "theme-ui";
import Layout from "../components/layout";
import ThemeableHeader from "../components/layout/themeable-header";
import ClosedTeaser from "../components/page-blocks/closed-teaser";
import ReusableLatestContent from "../components/page-blocks/reusable-latest-content";
import ClosedScroller from "../components/layout/closed-scroller";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import TwitterCallToAction from "../components/page-blocks/twitter-cta";
import Seo from "../components/seo";
import { getFlatFields } from "../lib/text-helpers";
import { useAllPosts } from "../data-hooks/all-posts";

const HomePage = ({ data: { homePageSplash, homeData, themeCategories } }) => {
  const themes = {};
  const posts = useAllPosts({ limit: 50 });
  // Loop over all posts
  posts.forEach(({ post }) => {
    // If they have a category
    if (post.themeCategories) {
      // Loop over each category
      post.themeCategories.nodes.forEach((cat) => {
        // If the theme is already created
        if (themes[cat.slug]) {
          // Push the post into that theme array
          themes[cat.slug].push(post);
        } else {
          // If the theme is not created
          // create an empty array
          themes[cat.slug] = [];
          // and push the post into it
          themes[cat.slug].push(post);
        }
      });
    }
  });
  return (
    <Layout>
      <Seo
        title="Home"
        description="Bringing together research, insight and ideas on assessment, qualifications and curriculum"
      />
      <ThemeableHeader
        backgroundImage={homePageSplash.c.f.src}
        subtitle="Bringing together research, insight and ideas on assessment, qualifications and curriculum"
        color="highlight"
      />
      <Grid as="section" columns={1} gap={[5, 6]} sx={{ my: [5, 6] }}>
        <ClosedTeaser
          {...getFlatFields(homeData?.homeFields?.featuredPost)}
          src={
            homeData?.homeFields?.featuredPost?.featuredImage?.node
              ?.mediaItemUrl
          }
        />
        <TwitterCallToAction />
        <ReusableLatestContent />
        <NewsletterSignup />
        {themeCategories.themes.map((theme) => {
          // If there aren't enough posts, don't render a scroller
          if (themes[theme.slug]?.length < 3 || !themes[theme.slug])
            return null;
          return (
            <ClosedScroller
              title={theme.name}
              posts={themes[theme.slug]}
              description={theme.description}
            />
          );
        })}
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  {
    themeCategories: allWpThemeCategory {
      themes: nodes {
        name
        slug
        description
      }
    }
    homePageSplash: file(relativePath: { eq: "landing-image.jpg" }) {
      c: childImageSharp {
        f: fluid(maxWidth: 1000, quality: 60) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    homeData: wpPage(slug: { eq: "home" }) {
      id
      homeFields {
        featuredPost {
          ... on WpDataStory {
            id
            title
            postType: nodeType
            slug
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            additionalInfo {
              standfirst
            }
            themeCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpTimeline {
            id
            title
            postType: nodeType
            slug
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            additionalInfo {
              standfirst
            }
          }
          ... on WpBriefing {
            id
            title
            postType: nodeType
            slug
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            additionalInfo {
              standfirst
            }
            themeCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpPost {
            id
            title
            postType: nodeType
            slug
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            additionalInfo {
              standfirst
            }
            themeCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpPublication {
            id
            title
            postType: nodeType
            slug
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            additionalInfo {
              standfirst
            }
            themeCategories {
              nodes {
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
